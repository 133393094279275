import React from 'react';
import './Spinner.styles.css'; // Importamos el CSS para el spinner

const Spinner = (props) => {
  return (
    <div className={props.fullscreen ? "spinner-container" : ""}>
      <div className="spinner"></div>
    </div>
  );
}

export default Spinner;
