import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import './SearchBar.styles.css';

const SearchBar = ({onSearch}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
    onSearch && onSearch(e.target.value);
  };

  return (
    <div className="search-container">
      <input
        className="search-input"
        type="text"
        placeholder="Buscar producto..."
        value={searchTerm}
        onChange={handleChange}
      />
      <button className="search-button">
        <FontAwesomeIcon color={"#36247E"} icon={faSearch} />
      </button>
    </div>
  );
}

export default SearchBar;
