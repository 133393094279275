import React, {useState, useEffect, useRef} from 'react';
import logo from './assets/images/logo.svg';
import './App.css';
import SearchBar from "./components/SearchBar/SearchBar";
import Product from "./components/Product/Product";
import NumberStepper from "./components/NumberStepper/NumberStepper";
import Spinner from "./components/Spinner/Spinner";

let _isProgrammaticScroll = false;

function App() {
  const url = window.location.href;
  const match = url.match(/\/c\/(\d+)(?:\/(\d+))?/);
  const [value1, value2] = match ? match.slice(1) : ["", ""];
  console.log('Valor 1: ', value1);
  console.log('Valor 2: ', value2);

  const numberStepperRef = useRef();
  const [products, setProducts] = useState(null);
  const [enabled, setEnabled] = useState(null);
  const [message, setMessage] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState(null);  // Productos filtrados para mostrar

  const findHighestPage = (productsArray) => {
    return Math.max(...productsArray.map(product => product.page));
  };

  const handleSearch = (searchTerm) => {
    if (searchTerm === '') {
      setFilteredProducts(null);
      return;
    }

    const isNumber = !isNaN(searchTerm);
    const regex = new RegExp(searchTerm, 'i');  // Insensible a mayúsculas/minúsculas

    if (isNumber) {
      const filtered = products.filter(product => String(product.code).match(regex));
      setFilteredProducts(filtered);
      return;
    }

    const foundInTitle = products.some(product => product.title.match(regex));
    if (foundInTitle) {
      const filtered = products.filter(product => product.title.match(regex));
      setFilteredProducts(filtered);
      return;
    }

    const foundInName = products.some(product => product.name.match(regex));
    if (foundInName) {
      const filtered = products.filter(product => product.name.match(regex));
      setFilteredProducts(filtered);
      return;
    }

    setFilteredProducts([]);
  };

  const groupPagesByPairs = (products) => {
    if (!products) {
      return [];
    }

    // Obtener todas las páginas únicas, incluyendo las que no tienen productos
    const uniquePages = new Set();
    products.forEach(product => uniquePages.add(product.page));
    for (let i = 1; i <= findHighestPage(products); i++) {
      uniquePages.add(i); // Incluir todas las páginas hasta la más alta
    }

    const pages = Array.from(uniquePages).sort((a, b) => a - b);

    const pairs = [];
    for (let i = 0; i < pages.length; i += 2) {
      const firstPage = pages[i];
      const secondPage = pages[i + 1] || firstPage;

      // Filtrar productos normales y destacados para cada página
      const filterProducts = (page) => products.filter(product => product.page === page && !product.highlight);
      const filterHighlights = (page) => products.filter(product => product.page === page && product.highlight);

      const productsFirstPage = filterProducts(firstPage);
      const productsSecondPage = secondPage !== firstPage ? filterProducts(secondPage) : [];

      const highlightsFirstPage = filterHighlights(firstPage);
      const highlightsSecondPage = secondPage !== firstPage ? filterHighlights(secondPage) : [];

      // Añadir primero productos normales y luego destacados
      const allProducts = [...productsFirstPage, ...productsSecondPage, ...highlightsFirstPage, ...highlightsSecondPage];

      // Solo añadir el par si hay productos
      if (allProducts.length > 0) {
        const pair = {
          label: secondPage ? `${firstPage}/${secondPage}` : `${firstPage}`,
          products: allProducts
        };

        pairs.push(pair);
      }
    }

    return pairs;
  }



  const pairedPages = groupPagesByPairs(products);

  const getUniqueSortedPages = (products) => {
    const pagesSet = new Set();
    products.forEach(item => {
      pagesSet.add(item.page);
    });
    return [...pagesSet].sort((a, b) => a - b);
  }

  const handleNumberStepperChange = (displayNumber, scroll) => {

    _isProgrammaticScroll = scroll;

    const pageDiv = document.getElementById(`page-${displayNumber}`);
    const numberStepperContainer = document.querySelector('.numberStepperContainer');
    const productsContainer = document.querySelector('.products');
    const header = document.querySelector('header'); // Seleccionamos el header

    if (pageDiv && numberStepperContainer && productsContainer) {
      const offsetFromTopOfProducts = pageDiv.offsetTop;
      const numberStepperHeight = numberStepperContainer.offsetHeight;
      const headerHeight = header ? header.offsetHeight : 0; // Obtenemos la altura del header, o 0 si no existe

      // Ahora restamos también la altura del header
      const scrollPosition = offsetFromTopOfProducts - numberStepperHeight - headerHeight;

      if (_isProgrammaticScroll) {
        productsContainer.scrollTo({
          top: scrollPosition,
          behavior: 'smooth'
        });
      }
    }
  };

  useEffect(() => {
    const productsContainer = document.querySelector('.products');

    const onScroll = () => {
      if (_isProgrammaticScroll) {
        return;
      }

      const numberStepperBottom = document.querySelector('.numberStepperContainer').getBoundingClientRect().bottom;
      pairedPages.forEach(pair => {
        const pageDiv = document.getElementById(`page-${pair.label}`);
        if (pageDiv) {
          const pageTop = pageDiv.getBoundingClientRect().bottom;
          if (pageTop >= numberStepperBottom && pageTop < window.innerHeight) {
            const pageNumberDiv = pageDiv.querySelector('.page-number');
            const pageNumber = pageNumberDiv.textContent;
            if (numberStepperRef.current) {
              numberStepperRef.current.updateDisplayNumber(pageNumber);
            }
          }
        }
      });
    };

    const onScrollEnd = () => {
      if (_isProgrammaticScroll) {
        _isProgrammaticScroll = false
      }
    }

    if (productsContainer) {
      productsContainer.addEventListener('scroll', onScroll);
      productsContainer.addEventListener('scrollend', onScrollEnd);
    }

    // autoscrolling from url
    if (value2 && numberStepperRef.current) {
        numberStepperRef.current.updateDisplayNumber(value2, true);
    }

    return () => {
      if (productsContainer) {
        productsContainer.removeEventListener('scroll', onScroll);
        productsContainer.removeEventListener('scrollend', onScrollEnd);
      }
    };
  }, [products]);

  useEffect(() => {

    const addHighlightsToProducts = (products, highlights) => {
      // Crear copia de productos para no modificar el array original
      const updatedProducts = [...products];

      // Verificar si highlights existe y agregar cada uno a la lista de productos
      if (highlights && Array.isArray(highlights)) {
        highlights.forEach(highlight => {
          updatedProducts.push({
            ...highlight,
            highlight: true
          });
        });
      }

      return updatedProducts;
    };

    // Realizar la solicitud HTTP para obtener los datos del archivo JSON
    fetch(`https://woopi.com.ar/catalogs/${value1}.json`)
      .then(response => response.json())
      .then(data => {
        // Actualiza el estado con los datos obtenidos
        setProducts(addHighlightsToProducts(data.products, data.highlights) || []);
        setEnabled(data.enabled || false);
        setMessage(data.message || '');
      })
      .catch(error => {
        setProducts([]);
        setEnabled(false);
        setMessage('Error al obtener los datos');
      });
  }, []); // El array vacío asegura que el efecto se ejecute solo una vez al montar el componente


  return (
    enabled ?
      <div className="violetta-container">
        <header>
          <div className="logo"><img src={logo} alt="Violetta"/></div>
          <SearchBar onSearch={handleSearch}/>
          {value1 &&
            <section className="campaign">
              CAMPAÑA {value1}
            </section>
          }
        </header>

        <div className="numberStepperContainer" style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
          {!filteredProducts && products && products.length &&
            <NumberStepper ref={numberStepperRef} onSearch={handleSearch} maxPage={findHighestPage(products)}
                           allowedPages={getUniqueSortedPages(products)}
                           onDisplayNumberChange={handleNumberStepperChange}/>}
        </div>
        <article className="products">
          {filteredProducts === null ? pairedPages.map((pair, index) => (
              <div key={pair.label} id={`page-${pair.label}`}>
                {/* Mostrar el número de las páginas como subtítulo */}
                <div className="page-number">{pair.label}</div>

                <section className="page">
                  {pair.products.map(product => (
                    <Product
                      key={product.code || product.message}
                      code={product.code?.toString()}
                      product={product.title}
                      name={product.name || product.message}
                      previousPrice={Number(product.priceOld).toFixed(2)}
                      price={Number(product.price).toFixed(2)}
                      highlight={product.highlight}
                    />
                  ))}
                </section>

                {/* Agregar el separador si no es el último grupo */}
                {index !== pairedPages.length - 1 && <hr className="separator"/>}
              </div>
            ))
            : filteredProducts && filteredProducts.length ?
              filteredProducts.map(product => (
                <Product
                  key={product.code || product.message}
                  code={product.code?.toString()}
                  product={product.title}
                  name={product.name}
                  price={Number(product.price).toFixed(2)}
                  previousPrice={Number(product.priceOld).toFixed(2)}
                />
              ))
              :
              <h1 className="products-title">No se encontraron productos</h1>
          }
        </article>
      </div>
      :
      (enabled === null || products === null) ?
        <div className="fullscreen-container">
          <div className="logo"><img src={logo} alt="Violetta"/></div>
          <div className="message"><Spinner/></div>
        </div>
        :
        <div class="fullscreen-container">
          <div className="logo"><img src={logo} alt="Violetta"/></div>
          <div className="message">{message ?? 'Los precios aún no están disponibles'}</div>
        </div>
  );
}

export default App;
