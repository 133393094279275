import React from 'react';
import './Product.styles.css';

const Product = ({ product, name, price, previousPrice, code, highlight }) => {
  return (
    <div className={`product ${highlight ? 'highlight' : ''}`}>
      <div className="product-details">
        <div className="product-code">{code}</div>
        <div className="product-title">{product}</div>
        <div className="product-name">{name}</div>
      </div>
      {!highlight &&
        <div className="product-prices">
          {previousPrice && previousPrice !== '0.00' && previousPrice !== '' && <div className="product-previous-price">${previousPrice}</div>}
          <div className="product-price">${price.split('.')[0]}<sup>{price.split('.')[1]}</sup></div>
        </div>
      }
    </div>
  );
}

export default Product;
